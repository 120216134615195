import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="appraisal"
export default class extends Controller {
  connect() {
    customSelect2('#appraisal_employee_id', true, 'Please Select', true);
    $(".filter-btn").click(function(){
      $(".organizer-appraisal").toggleClass("order-enable");
      $(".organizer-appraisal-template").toggleClass("order-enable");
    });
    $(".AppraisalForm").validate({
      rules: {
        "appraisal[employee_id]": {
          required: true,
        },
        "appraisal[last_appraisal_date]": {
          required: true
        },
        "appraisal[current_ctc]": {
          required: true,
          number_regex: true,
          maxlength: 10
        },
        "appraisal[increased_ctc]": {
          maxlength: 10
        },
        "appraisal[status]": {
          required: true
        },
      },
      messages: {
        "appraisal[employee_id]": {
          required: "Please select employee."
        },
        "appraisal[last_appraisal_date]": {
          required: "Please enter last appraisal date."
        },
        "appraisal[current_ctc]": {
          required: 'Please enter current ctc.',
          maxlength: 'Only 10 digit are allowed'
        },
        "appraisal[increased_ctc]": {
          maxlength: 'Only 10 digit are allowed',
          number_regex: true,
        },
        "appraisal[status]": {
          required: 'Please select status.'
        },
      }
    });
    $.validator.addMethod('number_regex', function(value, element) {
      return this.optional(element) || /^[\d,]+$/.test(value);
    }, 'Only digits and commas are allowed');

    $('#appraisal_last_appraisal_date').datepicker({
        format: 'dd/mm/yyyy',
        endDate: '+0d',
        autoclose: true
    })
    $('#appraisal_next_appraisal_date').datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true
    })

    $(".filter-btn").click(function(){
      $(".employee-my-appraisal").toggleClass("order-enable");
    });    

    $(".filter-btn").click(function(){
      $(".employee-team-appraisal").toggleClass("order-enable");
    }); 

    $('#appraisal_status').on('change', function(){
      var selectedStatus = $(this).val();
      const apraisalIncreasedCtc = $('#appraisal_increased_ctc')
      if(selectedStatus == 'initial'){
        apraisalIncreasedCtc.prop('required', false).prop('disabled', true);
        $('.increased_star').hide();
      }else{
        apraisalIncreasedCtc.prop('required', true).prop('disabled', false);
        $('.increased_star').show();
      }
    })

  }
}
