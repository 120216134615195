import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="template-form"
export default class extends Controller {
  connect() {
    $(".TemplateForm").validate();
    $('#add-questions').on('click', function() {
      var cloning_element = $('#cloning_element .question-and-answer-type').clone()
      $('.form-building ul').append(cloning_element)
      addMoreQuestion()
    });
  }
}

window.addMoreQuestion =  function(){
  $('.question-and-answer-type').each(function(index, item) {
    var input = $(item).find('.template_form_feedback_form_questions__name')
    input.attr("name", `template_form[feedback_form][questions][${index}][name]`)
    $(input).attr('id', input.id + '_' + index)

    var input = $(item).find('.template_form_feedback_form_questions__answer_options')
    input.attr("name", `template_form[feedback_form][questions][${index}][answer][options][]`)
    $(input).attr('id', input.id + '_' + index)

    var input = $(item).find('.template_form_feedback_form_questions__answer_type')
    input.attr("name", `template_form[feedback_form][questions][${index}][answer][type]`)
    $(input).attr('id', input.id + '_' + index)
  })
  $(".TemplateForm").validate();
}
