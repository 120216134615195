// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import * as select2 from "select2";
import "./controllers"
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap
// import "./theme.js"

import jQuery from 'jquery'
window.jQuery = jQuery
window.$ = jQuery

require('jquery-validation')
import ahoy from "ahoy.js"
require("jquery")
require("@nathanvda/cocoon")

import './datepicker.js'
import "trix"
import "@rails/actiontext"
// import "./ckeditor5.js"
window.Highcharts = require('highcharts');

import HighchartsSankey from "highcharts/modules/sankey";
import HighchartsOrganization from "highcharts/modules/organization";
import HighchartsExporting from "highcharts/modules/exporting";
HighchartsSankey(Highcharts);
HighchartsOrganization(Highcharts);
HighchartsExporting(Highcharts);

Notification.requestPermission()

var option = {
'animation': true,
'autohide': true,
'delay': 1000
}
var toastElList = [].slice.call(document.querySelectorAll('.toast'))
var toastList = toastElList.map(function (toastEl) {
  return new bootstrap.Toast(toastEl, option)
})

$(window).on('popstate', function() { 
  console.log('popstate')
  // $(".modal").hide()
  $('.modal').remove()
  $('.modal-backdrop').remove()
});


$('a').click(function() { 
  console.log('link click')
  // $(".modal").hide()
  // $('.modal-backdrop').remove()
  // $('.modal.in, .modal.show').remove()
  $('.modal').remove()
  $('.modal-backdrop').remove()
});

window.customSelect2 = function(selector='select', multipart=false, placeholder='Please Select', allowClear=false){
  let $select = $(selector),
  $parent = $select.parent();
  $select.select2({
    dropdownParent: $parent,
    multipart: multipart,
    placeholder: placeholder,
    allowClear: allowClear,
    templateSelection: function(data) {
      let text = $parent[0].firstElementChild.id.split('_')
      if(text[text.length -1]=='code' && text[text.length -2]=='country'){
        return `${data.text.split(' ')[0]} ${data.text.split(' ')[2]} `
      }else {
        return data.text;
      }
    }
  });
}

window.menuOpenClose = function(view){
  var $wrapper = document.querySelector('#wrapper');
  $wrapper.classList.toggle('toggled');
  if ($wrapper.classList.contains('toggled')) {
    document.cookie = "sidebar=toggled";
  } else {
    document.cookie = "sidebar=";
  }
}
var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl, {"html": true})
})

var myDefaultAllowList = bootstrap.Tooltip.Default.allowList

// To allow td elements and data-bs-option attributes on td elements
myDefaultAllowList.a.push('data-controller')
myDefaultAllowList.div.push('data-controller')


window.addEventListener('click', function(e){
  if (document.getElementById('notification-section-bell').contains(e.target) || document.getElementById('notifications-list').contains(e.target)){
    // Clicked in box
  } else{
    $('#notifications-list .custom-dropdown-menu').hide()
  }
});

