import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="leave-category"
export default class extends Controller {
  connect() {
    var workAffectedCheckbox = $('.work-affected-status-checkbox');
    var workAffectedDescription = $('#leave_description_box');
    var workAffectedTask = $('.leave_task_box');

    if (!workAffectedCheckbox.prop('checked')) {
      workAffectedTask.hide();
      workAffectedDescription.hide();
    }
    $('#year').datepicker({
      format: 'yyyy',
      minViewMode: 'years',
      autoclose: true,
      maxDate: 0
    });
    var working_days = []
    var disabled_days = []
    var days = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6
    }

    $(".filter-btn").click(function(){
      $(".employee-leave").toggleClass("order-enable");
    });

    $('#leave_category_to_date').datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    })
    $('#leave_category_from_date').datepicker({
      format: 'yyyy',
      dateFormat: 'yyyy',
      autoclose: true,
      changeMonth: false,
      minViewMode: 'years',
    });
    
    $('#leaveCategoryForm').validate({
      rules: {
        "leave_category[name]":{
          required: true,
          nameregex:true,
          maxlength: 25,
          minlength: 3
        },
        "leave_category[description]":{
          required: true,
          minlength: 2,
          maxlength: 1000
        },
        "leave_category[allowed_count]":{
          required: true,
          min: 0,
          maxlength: 3
        },
        "leave_category[eligible]":{
          required: true,
          min: 0,
          maxlength: 2
        },
        "leave_category[from_date]":{
          required: true
        },
        "leave_category[to_date]":{
          required: true
        },
        "leave_category[encashment_percent]":{
          required: true,
          numregex: true,
          total_hundred: true,
          min: 1,
          max: 100
        },
        "leave_category[carry_forward_percent]":{
          required: true,
          numregex: true,
          total_hundred: true,
          min: 1,
          max: 100
        }
      },
      messages: {
        "leave_category[name]":{
          required: 'This is a required field.'
        },
        "leave_category[description]":{
          required: 'This is a required field.',
          maxlength: 'Please enter not more than 1000 characters.'
        },
        "leave_category[allowed_count]":{
          required: 'This is a required field.'
        },
        "leave_category[eligible]":{
          required: 'This is a required field.'
        },
        "leave_category[from_date]":{
          required: 'This is a required field.'
        },
        "leave_category[to_date]":{
          required: 'This is a required field.'
        },
        "leave_category[encashment_percent]":{
          required: 'This is a required field.',
          numregex: 'Contains decimal number only.'
        },
        "leave_category[carry_forward_percent]":{
          required: 'This is a required field.',
          numregex: 'Contains decimal number only.'
        }
      }
    })
    $('#leaveRejectForm').validate({
      rules:{
        "leave[reject_comment_description]":{
          required: true,
          minlength: 10
        }
      },
      messages:{
        "leave[reject_comment_description]":{
          required: 'Please enter description.',
          minlength: 'Minimum 10 character is required.'
        }
      }
    })

    $('#leaveApproveForm').validate({
      rules:{
        'leave[paid]':{
          required: true,
          number: true,
          min:0,
          digits: true
        },
        'leave[unpaid]':{
          required: true,
          number: true,
          min: 0
        }
      },
      messages:{
        'leave[paid]':{
          required: 'Please enter paid count.',
          number: 'Please enter a valid number',
          min: 'Please enter valid value.',
          digits: 'Please enter only numbers'
        },
        'leave[unpaid]':{
          required: 'Please enter unpaid count.',
          number: 'Please enter a valid number',
          min: 'Please enter valid value.'
        }
      }
    })

    $('.leaveSettingFrom').validate({
      rules:{
        "leave_setting[full_day]":{
          required: true,
        },
        "leave_setting[half_day]":{
          required: true,
        },
        "leave_setting[early_leave]":{
          required: true,
        },
      },
      messages:{
        "leave_setting[full_day]":{
          required: 'THis field is required.',
        },
        "leave_setting[half_day]":{
          required: 'THis field is required.',
        },
        "leave_setting[early_leave]":{
          required: 'THis field is required.',
        },
      }
    })

    

    $('.leaveFrom').validate({
      rules: {
        "leave[leave_type]":{
          required: true
        },
        "leave[to_date]":{
          required: true,
        },
        "leave[from_date]":{
          required: true,
          checkWorkingDays: true
        },
        "leave[num_of_days]":{
          required: true
        },
        "leave[leave_category_id]":{
          required: true
        },
        "leave[leave_reason]":{
          required: true,
          minlength: 10
        },
        "leave[is_planned]":{
          required: true
        },
        "leave[work_affected_description]":{
          required: true,
          minlength: 10
        }
      },
      messages: {
        "leave[leave_type]":{
          required: 'This is a required field.'
        },
        "leave[from_date]":{
          required: 'This is a required field.'
        },
        "leave[to_date]":{
          required: 'This is a required field.'
        },
        "leave[leave_category_id]":{
          required: 'This is a required field.'
        },
        "leave[leave_reason]":{
          required: 'This is a required field.',
          minlength: 'Minimum 10 characters are required.'
        },
        "leave[is_planned]":{
          required: 'This is a required field.'
        },
        "leave[work_affected_description]":{
          required: 'This is a required field.',
          minlength: 'Minimum 10 characters are required.'
        }
      }
    })
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^(?!\s)(?=.*[a-zA-Z])[a-zA-Z\s]+$/.test(value);
    },'Contains alphabets only');

    $.validator.addClassRules("task_option_list", {
      required: true,
    });  

    $.validator.addMethod('numregex', function(value, element){
      return this.optional(element) || /^\d{0,4}(\.\d{0,2})?$/i.test(value);
    },'Contains decimal number only');

    $.validator.addMethod('total_hundred', function(value, element){
      let encash = parseInt($('#leave_category_encashment_percent').val())
      let carryforward = parseInt($('#leave_category_carry_forward_percent').val())
      encash = isNaN(encash) ? 0 : encash;
      carryforward = isNaN(carryforward) ? 0 : carryforward;
      let total = encash + carryforward
      console.log(total);
      return this.optional(element) || (total <= 100);
    },'The sum of both value should not exceed 100');
    
    var updateLapseValue = function() {
      let encashmentPercent = parseInt($('#leave_category_encashment_percent').val()) || 0;
      let carryForwardPercent = parseInt($('#leave_category_carry_forward_percent').val()) || 0;
      console.log(encashmentPercent);
      console.log(carryForwardPercent);
      let total = encashmentPercent + carryForwardPercent;
      let lapsePercent = isNaN(total) ? 0 : 100 - total;

      $('#lapse_value').text('Lapsed : ' + lapsePercent + '%');
    };

    $('#leave_category_name').on('keyup', function(e){
      let temp = $('#leave_category_name').val()
      if((temp.length == 1 || (temp.charAt(temp.length-2) == ' ')) && temp.charAt(temp.length-1) == ' '){
        $('#leave_category_name').val(temp.slice(0,-1))
      }
    })

    $('#leave_category_encashment_applied, #leave_category_carry_forward_applied').change(function() {
      if (!$('#leave_category_carry_forward_applied').is(':checked')) {
        $('#leave_category_carry_forward_percent').val(0)
      }
      if (!$('#leave_category_encashment_applied').is(':checked')) {
        $('#leave_category_encashment_percent').val(0)
      }
      updateLapseValue();
    });

    updateLapseValue();
    $('#leave_category_encashment_percent, #leave_category_carry_forward_percent').keyup(function() {
      updateLapseValue();
    });   

    $('.work-affected-status-checkbox').change(function (argument) {
      var description = $('#leave_description_box')
      var task = $('.leave_task_box');
      if (this.checked) {
        description.show()
        task.show();
      }else{
        description.hide()
        task.hide();
      }
    })

    if ( $("#holiday").length != 0 ){ 
      var holidays = JSON.parse($("#holiday").text())
      holidays.shift()
      holidays.forEach((value) => working_days.push(days[value]));
      disabled_days = [0,1,2,3,4,5,6].filter((o) => working_days.indexOf(o) === -1)
    }

    var disabled_dates = []
    if ( $("#holiday-dates").length != 0 ){
      disabled_dates = JSON.parse($("#holiday-dates").text())
    }
    if ( $("#wfh-dates").length != 0 ){
      disabled_dates = JSON.parse($("#wfh-dates").text())
    }
    if ( $("#leave-dates").length != 0 ){
      disabled_dates = JSON.parse($("#leave-dates").text())
    }

    var allowedCount = parseInt($('#leave_balance').text());

    $.validator.addMethod("checkWorkingDays", function(value, element) {
      if ($("#is_earned").text() != 'true') {
        return true;
      }
      var workingDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
      var numOfWorkingDays = 0;
      var fromDate = $('#leave_from_date').val();
      fromDate = new Date(fromDate.split('/')[2], (parseInt(fromDate.split('/')[1]) - 1), fromDate.split('/')[0]);
      var toDate = $('#leave_to_date').val();
      toDate = new Date(toDate.split('/')[2], (parseInt(toDate.split('/')[1]) - 1), toDate.split('/')[0]);

      for (var currentDate = new Date(fromDate); currentDate <= toDate; currentDate.setDate(currentDate.getDate() + 1)) {
        var dayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
        var formattedDate = currentDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
        if ($.inArray(dayOfWeek, workingDays) !== -1 && $.inArray(formattedDate, holidays) === -1) {
          numOfWorkingDays++;
        }
      }
      var allowedCount = parseInt($('#leave_balance').text());
      return numOfWorkingDays <= allowedCount;
    }, function() {
      var allowedCount = parseInt($('#leave_balance').text());
      return 'You are not allowed to take more than ' + allowedCount + ' earned leave, please apply for ' + allowedCount + ' days only.';
    });

    $('#leave_from_date, #leave_to_date, #leave_leave_category_id').on('change', function(){
      if ($('#leave_from_date').val()!== '' && $('#leave_to_date').val()!== '' ) {
        setTimeout(function() {
          $('#leave_from_date').valid();
          $('#leave_to_date').valid();
        }, 1000);
      }
    });

    var startDate;
    $('.datepicker1').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+365d',
      autoclose: true,
      todayHighlight: true,
      daysOfWeekDisabled: disabled_days,
      datesDisabled: disabled_dates
    }).on('changeDate', function(selected) {
      if (!$(this).val()) {
        $('.datepicker2').datepicker('setStartDate', null);      
      } else if (selected.date != undefined) {
        startDate = new Date(selected.date.valueOf());
        $('.datepicker2').datepicker('setStartDate', startDate);
      }
    });

    $('.datepicker2').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+365d',
      autoclose: true,
      todayHighlight: true,
      daysOfWeekDisabled: disabled_days,
      datesDisabled: disabled_dates
    }).on('changeDate', function(selected) {
      if (!$(this).val()) {
        $('.datepicker1').datepicker('setEndDate', null);      
      } else if (selected.date != undefined) {
        var endDate = new Date(selected.date.valueOf());
        $('.datepicker1').datepicker('setEndDate', endDate);
        if (endDate < startDate){
          $('.datepicker2').datepicker('setDate', startDate);
        }
      }
    });
    $('#datepicker2').datepicker('setStartDate', $('#datepicker1').datepicker('getDate'));
    $('#datepicker1').datepicker('setEndDate', $('#datepicker2').datepicker('getDate'));

    jQuery(document).ready(function() {
      const employee_id =  $('#employee_id').text();
      const category =  $('#leave_category').text();
        $.ajax({
          type: "get",
          url: category + "/leave_balance_count." + employee_id
        });
    });

    jQuery(document).ready(function() {
      if ( $("#holiday").length != 0 ) {
        $.ajax({
          type: "get",
          url: "leave_balance/0"
        });
      }
    });

    $('#leave_leave_category_id').on('change', function(){
      var allowedCount = parseInt($('#leave_balance').text());
      if ($(this).val() != '') {
        $.ajax({
          type: "get",
          url: "leave_balance/"+$(this).val()
        });
      } else {
        $.ajax({
          type: "get",
          url: "leave_balance/0"
        });
      }
    });
    
    
    $('#year').on('change', function(){
      let employee_id =  $('#employee_id').text();
      let year = $('#year').val();
      
      if(employee_id.length) {
        $.ajax({
          type: 'get',
          url: '/organizers/leave_requests/'+ employee_id +'/update_leave_balance_sheet.'+year
        });  
      } else {
        $.ajax({
          type: 'get',
          url: '/employee/leaves/update_leave_balance_sheet.'+year
        });
      }
    });
    

    if ($('#leave_category_allowed').is(':checked')) {
      $('#allowed_count_section').addClass('d-block')
      $('#allowed_count_section').removeClass('d-none')
    }
    $('#leave_category_earned').change(function() {
      if ($(this).is(':checked')) {
        $('#leave_category_allowed').prop('checked', false);
        $('#allowed_count_section').addClass('d-none')
        $('#allowed_count_section').removeClass('d-block')
      }
    });

    $('#leave_category_allowed').change(function() {
      if ($(this).is(':checked')) {
        $('#allowed_count_section').addClass('d-block')
        $('#allowed_count_section').removeClass('d-none')
        $('#leave_category_earned').prop('checked', false)
      } else {
        $('#allowed_count_section').addClass('d-none')
        $('#allowed_count_section').removeClass('d-block')
      }
    });

    if ($('#leave_category_encashment_applied').is(':checked')) {
      $('#leave_encashment_field').addClass('d-block')
      $('#leave_encashment_field').removeClass('d-none')
    }
    if ($('#leave_category_carry_forward_applied').is(':checked')) {
      $('#leave_carry_forward_field').addClass('d-block')
      $('#leave_carry_forward_field').removeClass('d-none')
    }

    $('#leave_category_encashment_applied').change(function() {
      if ($(this).is(':checked')) {
        $('#leave_encashment_field').addClass('d-block')
        $('#leave_encashment_field').removeClass('d-none')
      } else {
        $('#leave_encashment_field').addClass('d-none')
        $('#leave_encashment_field').removeClass('d-block')
      }
    });

    $('#leave_category_carry_forward_applied').change(function() {
      if ($(this).is(':checked')) {
        $('#leave_carry_forward_field').addClass('d-block')
        $('#leave_carry_forward_field').removeClass('d-none')
      } else {
        $('#leave_carry_forward_field').addClass('d-none')
        $('#leave_carry_forward_field').removeClass('d-block')
      }
    });

    // $(document).on('change', '.task_option_list', function() {
    //   $('.task_option_list option').prop('disabled', false);
    //   var selectedValues = $('.task_option_list').map(function() { return $(this).val(); }).get();
    //   var selectedValues = selectedValues.filter(function(value) {
    //     return value !== "";
    //   });

    //   selectedValues.forEach(function(element) {
    //     $('.task_option_list').each(function(){
    //       if($(this).val() !== element ){
    //         $(this).find('option[value="' + element + '"]').prop('disabled', true);
    //       }
    //     })
    //   });

    // });

    function updateTaskOptionList() {
      $('.task_option_list option').prop('disabled', false);
      var selectedValues = $('.task_option_list').map(function() {
        return $(this).val();
      }).get();

      selectedValues = selectedValues.filter(function(value) {
        return value !== "";
      });

      selectedValues.forEach(function(element) {
        $('.task_option_list').each(function() {
          if ($(this).val() !== element) {
            $(this).find('option[value="' + element + '"]').prop('disabled', true);
          }
        });
      });
    }

    // Trigger on change event
    $(document).on('change', '.task_option_list', function() {
      updateTaskOptionList();
    });

    // Trigger on button click event
    $(document).on('click', '.remove-task-btn', function() {
      console.log('remove btn clicked')
      updateTaskOptionList();
    });

    $(document).on('cocoon:after-insert', function(event, insertedItem) {
      var selectedValues = $('.task_option_list').map(function(){ return $(this).val(); }).get();
      var selectedValues = selectedValues.filter(function(value) { return value !== ""; });
      selectedValues.forEach(function(element) {
        $(insertedItem).find('.task_option_list').find('option[value="' + element + '"]').prop('disabled', true);
      });
    });

    $('.leave_from_date').datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      todayHighlight: true
    }).on('changeDate input', function(selected) {
      if (!$(this).val()) {
        $('.leave_to_date').datepicker('setStartDate', null);      
      } else if (selected.date != undefined) {
        var minDate = new Date(selected.date.valueOf());
        $('.leave_to_date').datepicker('setStartDate', minDate);
      }
    });

    $('.leave_to_date').datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      todayHighlight: true
    }).on('changeDate input', function(selected) {
      if (!$(this).val()) {
        $('.leave_from_date').datepicker('setEndDate', null);      
      } else if (selected.date != undefined) {
        var maxDate = new Date(selected.date.valueOf());
        $('.leave_from_date').datepicker('setEndDate', maxDate);
      }
    });
    $('#from_date').datepicker('setEndDate', $('#to_date').datepicker('getDate'));
    $('#to_date').datepicker('setStartDate', $('#from_date').datepicker('getDate'));
    loadSelectForLeaveTask()
  }

  addLeaveTask(){
    $(".add-leave-task a").click()
    if($('.nested-fields').length > 1){
      $('.no_leave_task').hide()
    }else{
      $('.no_leave_task').show()
    }
    loadSelectForLeaveTask()
  }
  updateLapseValue() {
    let encashmentPercent = parseInt($('#leave_category_encashment_percent').val()) || 0;
    let carryForwardPercent = parseInt($('#leave_category_carry_forward_percent').val()) || 0;

    let total = encashmentPercent + carryForwardPercent;
    let lapsePercent = isNaN(total) ? 0 : 100 - total;

    $('#lapse_value').text('Lapse %(days) : ' + lapsePercent + '%');
  }
}
window.loadSelectForLeaveTask = function() {
  $('.task_option_list, .leave_task_priority, .leave_task_employee').each(function(index, item) {
    if($(item).data('selecttwo') == true){
      $(item).select2()
    }
  })
}


