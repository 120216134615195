import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="project"
export default class extends Controller {
  connect() {
    $(".filter-btn").click(function(){
      $(".organizer-project").toggleClass("order-enable");
      $(".organizer-project-status").toggleClass("order-enable");
      $(".organizer-project-hour").toggleClass("order-enable");
    });

    $(".filter-btn").click(function(){
      $(".organizer-project").toggleClass("order-enable");
    });

    console.log('hello from billing department.')
    validatorBasic()
    customSelect2('#project_employee_id', true);
    customSelect2('#project_status', true);
    // customSelect2('#organizer_project_status', true);
    // customSelect2('#organizer_project_type', true);
    customSelect2('#project_manager', true, 'Please Select', true);
    customSelect2('#project_type', true, 'Please Select', true);
    customSelect2('#designations', true, 'Please Select designation', true);

    $("#project_billing_information_per_unit_price").on('input', function() {
      var val = $(this).val();
      // Remove any characters after the second decimal point
      if(val.indexOf('.') !== -1) {
        var splitVal = val.split('.');
        if(splitVal[1].length > 2) {
          splitVal[1] = splitVal[1].substring(0, 2);
          val = splitVal.join('.');
          $(this).val(val);
        }
      }
    });

    $('.projectFrom').validate({
      rules: {
        "project[project_name]": {
          required: true,
          minlength: 2,
          maxlength: 100
        },
        "project[client_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 25
        },
        "project[content]": {
          required: true,
          minlength: 2
        }
      },
      messages: {
        "project[project_name]": {
          required: 'This is a required field.',
          minlength: 'Must be 2-100 characters long',
          maxlength: 'Must be 2-100 characters long'
        },
        "project[client_name]": {
          required: 'This is a required field.',
          nameregex: 'The client name can only consist of alphabets.',
          minlength: 'Must be 2-25 characters long',
          maxlength: 'Must be 2-25 characters long'
        }
      },
      "project[content]": {
          required: 'This is a required field.',
          minlength: 'Must be 2-500 characters long'
        }
    })

    $('.billingForm').validate({
      rules: {
        "project_billing_information[unit]": {
          required: true,
        },
        "project_billing_information[currency]": {
          required: true,
        },
        "project_billing_information[per_unit_price]": {
          required: true,
          minlength: 1,
          maxlength: 12
        }
      },
      messages: {
        "project_billing_information[unit]": {
          required: 'This is a required field.',
        },
        "project_billing_information[currency]": {
          required: 'This is a required field.',
        }
      },
      "project_billing_information[per_unit_price]": {
          required: 'This is a required field.',
          minlength: 'Please enter valid price.',
          maxlength: 'Please enter valid unit price'
        }
    })
    console.log($('#project_billing_information_per_unit_price'))
    $('#project_billing_information_per_unit_price').on("keydown", function(event) {
      console.log(event.key)
      if(event.key === '-' || event.key === '+') {
        event.preventDefault();
      }
    });
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z. ]+$/.test(value);
    },'Contains alphabets only');

    $('#project_start_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#project_end_date').datepicker('setStartDate', new Date());
        } else if (selected.date != undefined) {
          var minDate = new Date(selected.date.valueOf());
          $('#project_end_date').datepicker('setStartDate', minDate);
        }
      })

    $('#project_end_date').datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#project_start_date').datepicker('setEndDate', new Date());      
        } else if (selected.date != undefined) {
          var maxDate = new Date(selected.date.valueOf());
          $('#project_start_date').datepicker('setEndDate', maxDate);
        }      
      })

    var workHourFromDate = $('#project_start_date').datepicker('getDate');
    if (workHourFromDate != null){
      $('#project_end_date').datepicker('setStartDate', workHourFromDate);
    }

    var workHourToDate = $('#project_end_date').datepicker('getDate');
    if (workHourToDate != null){
      $('#project_start_date').datepicker('setEndDate', workHourToDate);
    }

    if( $('#check_permission').text() == 'true' ) {
      $(".projectFrom :input").prop("disabled", true);
      $('#project_content').prop("contenteditable",false);
      $("#trix-toolbar-1").addClass('d-none');
    }

    $(".filter-btn").click(function(){
      $(".employee-my-project").toggleClass("order-enable");
    });

    document.addEventListener('turbo:load', () => {
      this.initializeDatatable();
    });
    document.addEventListener('refreshDataTable', () => {
      this.refreshDataTable();
    });

    $('#designations').change(function() {
      var designations = $(this).val();
      console.log('my designation : ' +  designations)
      $.ajax({
        url: $('#project-head-count-datatable').data('source'),
        method: 'GET',
        data: { designations: designations },
        success: function(response) {
        }
      });
    });

    let daily_status = $('#current_day_daily_status').val()
    if(daily_status != undefined){
      const current_day_daily_status = JSON.parse(daily_status);
      daliy_status_chart(current_day_daily_status.done, current_day_daily_status.hold, current_day_daily_status.in_progress, current_day_daily_status.backlogs, current_day_daily_status.todo)
    }

  }

  initializeDatatable() {
    if (!$.fn.DataTable.isDataTable('#project-head-count-datatable')) {
      console.log('checking the load')
      $('#project-head-count-datatable').dataTable({
        "orderCellsTop": true,
        "processing": true,
        "pagingType": "simple_numbers",
        "searching": false,
        "oLanguage": {
          "sLengthMenu": "Show Entries _MENU_",
          "sProcessing": "<div class='ld_ind_wrp'></div>",
          "sSearch": "",
          "oPaginate": {
            "sNext": '<i class="bi bi-chevron-right"></i>',
            "sPrevious": '<i class="bi bi-chevron-left"></i>'
          },
        },
        "dom": "<'row custom_data_filter_col'<'col-sm-12 custom_datatable'f>>" +
          "<'row'<'col-sm-12 table-responsive'tr>>" +
        "<'row mgltm0'<'col-sm-5 show-entry'li><'col-sm-7'p>>"+ "<'row'<'col-sm-12'B>>" ,
          'buttons': [
            {
              extend: 'csv',
              text: 'Export CSV',
            },
            {
              extend: 'excel',
              text: 'Export Excel',
            }
          ],
      });
    }
  }

  refreshDataTable() {
    if ($.fn.DataTable.isDataTable('#project-head-count-datatable')) {
      $('#project-head-count-datatable').DataTable().destroy();
    }
    this.initializeDatatable();
  }
}
