import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="feedback-form-sends"
export default class extends Controller {
  connect() {
    $(".FeedbackFormSend").validate()
    customSelect2('.select2', false);

    if($('#appraisal-feedback-form .nested-fields-prev-jobs').length == 0){
      $('#appraisal-feedback-form .add_fields').click()
    }
    setTimeout(function() {
      loadDatePickerOnPrevJob();
    }, 100)
    $('#appraisal-feedback-form .add_fields').click(function() {
      setTimeout(function() {
        loadDatePickerOnPrevJob();
      }, 100)
    })

    $('#appraisal-feedback-form').find(".nested-fields").each(function(index, element) {
      console.log('test-feed')
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          $(input).datepicker({
            format: 'dd/mm/yyyy',
            startDate: '+0d',
            autoclose: true
          })
          if($(input).attr('class').includes('form_expiry_date')){
            $(input).rules( "add", {
              required: true,
              messages: {
                required: "Please enter deadline date.",
              }
            });
          }
        }
      })
    })

    customSelect2('#feedback_form_send_template_form_id', true);
    customSelect2('#feedback_form_send_employee_id', true);

    $('#feedback_form_send_form_expiry_date').datepicker({
      format: 'dd/mm/yyyy',
      startDate: '+0d',
      autoclose: true
    })
  }
}


window.loadDatePickerOnPrevJob = function() {
  $('.date-picker').each(function(index, item) {
    $(item).datepicker({
      format: 'dd-mm-yyyy',
      autoclose: true,
      startDate: '01/01/1900',
      endDate: new Date().toLocaleDateString('en-GB')
    })
  })
}
