import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="application-v2--employee-validation"
export default class extends Controller {
  connect() {

    $("#join_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      endDate: "today",
    }).on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        $('#seperation_date').datepicker('setStartDate', minDate);
      })

    window.loadDatePickerOnPrevJob = function() {
      $('.date-picker').each(function(index, item) {
        $(item).datepicker({
          format: 'dd-mm-yyyy',
          autoclose: true,
          startDate: '01/01/1900',
          endDate: new Date().toLocaleDateString('en-GB')
        })
      })
    }
    customSelect2('#employee_country_code', true);
    $("#employee_joining_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    });
    $("#emp-join-date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    });    
    $("#employee_date_of_birth").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      startDate: '01/01/1900',
      endDate: '-18Y'
    });

    $("#employee_actual_date_of_birth").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      startDate: '01/01/1900',
      endDate: new Date().toLocaleDateString('en-GB')
    });

    $("#employee_wedding_anniversy").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      startDate: '01/01/1900',
      endDate: new Date().toLocaleDateString('en-GB')
    });


    $("#academic_start_date").datepicker({
      format: "dd-mm-yyyy",
      autoclose: true,
    }).on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        $('#academic_end_date').datepicker('setStartDate', minDate);
      })

    $("#employee_seperation_date").datepicker({
        format: "dd-mm-yyyy",
        autoclose: true,
    })
    $('.edit_employee').validate({
      rules: {
        // employee validations
        "employee[first_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 15
        },
        "employee[last_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 15
        },
        'employee[designation_id]':{
          required: true
        },
        "employee[gender]": {
          required: true
        },
        "employee[date_of_birth]": {
          required: true
        },
        "employee[marital_status]": {
          required: true
        },
        "employee[job_title]": {
          required: true,
          nameregex: true
        },
        "employee[joining_date]": {
          required: true
        },
        "employee[contact_number]": {
          phoneregex: true,
          number: true,
          minlength: 10,
          maxlength: 10,
          required: true
        },
        "employee[alt_mobile]": {
          phoneregex: true,
          number: true,
          minlength: 10,
          maxlength: 10
        },
        "employee[password]":{
          required: false
        },
        "employee[email]":{
          required: true,
          email: true,
          emailregex: true
        },
        "employee[personal_mail]": {
          required: true,
          email: true,
          emailregex: true
        },
        "employee[pancard_number]": {
          alphanumeric: true,
          minlength: 10,
          maxlength: 10
        },
        "employee[aadhar_number]": {
          required: true,
          number: true,
          minlength: 12,
          maxlength: 12
        },
        "employee[card_number]": {
          phoneregex: true,
          number: true,
          minlength: 10,
          maxlength: 10
        }
      },
      messages: {
        // Employee validation messages
        "employee[first_name]": {
          required: 'First name is required.',
          nameregex: 'First name can only consist of alphabets.',
          minlength: 'First name must be 2-15 characters long.',
          maxlength: 'First name must be 2-15 characters long.'
        },
        "employee[last_name]": {
          required: 'Last name is required.',
          nameRegex: 'Last name can only consist of alphabets.',
          minlength: 'Last name must be 2-14 characters long.',
          maxlength: 'Last name must be 2-15 characters long.'
        },
        'employee[designation_id]':{
          required: 'Employee designation is required'
        },
        "employee[gender]": {
          required: 'Gender is required.'
        },
        "employee[date_of_birth]": {
          required: 'Date of birth is required.'
        },
        "employee[marita_status]": {
          required: 'Marital status is required.'
        },
        "employee[job_title]": {
          required: 'Job title is required.'
        },
        "employee[joining_date]": {
          required: 'Joining date is required.'
        },
        "employee[contact_number]": {
          phoneregex: 'Phone contain only 10 digits.',
          number: 'Only numbers are allowed.',
          maxlength: 'Maximum 10 digits are allowed.',
          required: 'Phone number is required.'
        },
        "employee[alt_mobile]": {
          phoneregex: 'Phone contain only 10 digits.',
          number: 'Only numbers are allowed.',
          maxlength: 'Maximum 10 digits are allowed.'
        },
        "employee[password]":{
           required: 'Passsword is required'
        },
        "employee[email]":{
          required: 'Official Email is required.',
          email: 'Email pattern must followed.',
          emailregex: 'Email must only contains @ and . as a special character'
        },
        "employee[personal_mail]": {
          required: 'Personal email is required',
          email: 'Email pattern must followed.',
          emailregex: 'Email must only contains @ and . as a special character'
        },
        "employee[pancard_number]": {
          alphanumeric: 'Enter valid pancard',
          maxlength: 'pancard must contains 10 digits'
        },
        "employee[aadhar_number]": {
          required: 'Adharcard number is required',
          number: 'Only numbers are allowed.',
          maxlength: 'Aadhar number must consist of 12 digits',
          minlength: 'Aadhar number must consist of 12 digits'
        },
        "employee[card_number]": {
          phoneregex: 'Card number contain only 10 digits.',
          number: 'Only numbers are allowed.',
          minlength: 'Card Number must be 10 digits long.',
          maxlength: 'Card Number must be 10 digits long.'
        }
      }
    })

  
  $('.edit_employee_new').validate({
      rules: {
        // employee validations
        "employee[first_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 15
        },
        "employee[last_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 15
        },
        'employee[designation_id]':{
          required: true
        },
        "employee[gender]": {
          required: true
        },
        "employee[date_of_birth]": {
          required: true
        },
        "employee[marital_status]": {
          required: true
        },
        "employee[job_title]": {
          required: true,
          nameregex: true
        },
        "employee[joining_date]": {
          required: true
        },
        "employee[contact_number]": {
          phoneregex: true,
          number: true,
          minlength: 10,
          maxlength: 10,
          required: true
        },
        "employee[alt_mobile]": {
          phoneregex: true,
          number: true,
          minlength: 10,
          maxlength: 10
        },
        "employee[password]":{
          required: true,
          passwordregex: true,
          minlength: 8,
          maxlength: 15
        },
        "employee[email]":{
          required: true,
          email: true,
          emailregex: true
        },
        "employee[personal_mail]": {
          email: true,
          emailregex: true
        },
        "employee[card_number]": {
          phoneregex: true,
          number: true,
          minlength: 10,
          maxlength: 10
        },
      },
      messages: {
        // Employee validation messages
        "employee[first_name]": {
          required: 'First name is required.',
          nameregex: 'First name can only consist of alphabets.',
          minlength: 'First name must be 2-15 characters long.',
          maxlength: 'First name must be 2-15 characters long.'
        },
        "employee[last_name]": {
          required: 'Last name is required.',
          nameRegex: 'Last name can only consist of alphabets.',
          minlength: 'Last name must be 2-14 characters long.',
          maxlength: 'Last name must be 2-15 characters long.'
        },
        'employee[designation_id]':{
          required: 'Employee designation is required'
        },
        "employee[gender]": {
          required: 'The gender is required.'
        },
        "employee[date_of_birth]": {
          required: 'Date of birth is required.'
        },
        "employee[marita_status]": {
          required: 'Marita status is required.'
        },
        "employee[job_title]": {
          required: 'Job title is required.'
        },
        "employee[joining_date]": {
          required: 'Joining date is required.'
        },
        "employee[contact_number]": {
          phoneregex: 'Please enter valid number',
          number: 'Only numbers are allowed.',
          maxlength: 'Maximum 10 digits are allowed.',
          required: 'Phone number is required.'
        },
        "employee[alt_mobile]": {
          phoneregex: 'Please enter valid number',
          number: 'Only numbers are allowed.',
          maxlength: 'Maximum 10 digits are allowed.'
        },
        "employee[password]":{
          required: 'Password is required.',
          minlength: 'Password must be 8-15 characters long',
          maxlength: 'Password must be 8-15 characters long'
        },
        "employee[oversight_lead_id]": {
          required: 'Employee oversight role is required'
        },
        "employee[email]":{
          required: 'Official Email is required.',
          email: 'Email pattern must followed.',
          emailregex: 'Email must only contains @ and . as a special character'
        },
        "employee[personal_mail]": {
          email: 'Email pattern must followed.',
          emailregex: 'Email must only contains @ and . as a special character'
        },
        "employee[card_number]": {
          phoneregex: 'Card number contain only 10 digits.',
          number: 'Only numbers are allowed.',
          minlength: 'Card Number must be 10 digits long.',
          maxlength: 'Card Number must be 10 digits long.'
        }
      }
    })
    $('#address-form').validate()
    $('#addresses').find(".nested-fields").each(function(index, element) {
      console.log(index, 'index');
      console.log(element)
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          if($(input).attr('class').includes('address')){
            console.log(input, 'console.log(element)')
            $(input).rules( "add", {
              required: true,
              messages: {
                required: "Addresses can't be blank",
              }
            });
          }else if($(input).attr('class').includes('city')){
             $(input).rules( "add", {
              required: true,
              nameregex: true,
              maxlength: 25,
              messages: {
                required: "City can't be blank",
                nameregex: "Contains alphabets only",
                maxlength: "Maximum 25 characters are allowed.",
              }
            });
          }else if($(input).attr('class').includes('state')){
             $(input).rules( "add", {
              required: true,
              maxlength: 50,
              nameregex: true,
              messages: {
                required: "State can't be blank",
                nameregex: "Contains alphabets only",
                maxlength: "Maximum 50 characters are allowed.",
              }
            });
          }else if($(input).attr('class').includes('pincode')){
             $(input).rules( "add", {
              pincoderegex: true,
              required: true,
              maxlength: 6,
              minlength: 6,
              messages: {
                pincoderegex: "Contains digits only",
                required: "Pincode can't be blank",
                maxlength: "Pincode must be 6 digits long",
                minlength: "Pincode must be 6 digits long",
              }
            });
          }
        }
      })
    })

    $('#academic-form').validate()
    $('#academic_details').find(".nested-fields").each(function(index, element) {
      console.log('logged')
      $(element).find('input').each(function(i, input) {
      console.log('element', element)

        if ($(input).attr('type') == 'text') {
      console.log('element', input)

          if($(input).attr('class').includes('institute_name')){
            $(input).rules( "add", {
              required: true,
              maxlength: 150,
              nameregex: true,
              messages: {
                required: "Institute Name can't be blank",
                maxlength: 'Maximum 150 characters are allowed.',
                nameregex: 'Only allow letters'
              }
            });
          }else if ($(input).attr('class').includes('degree')) {
            $(input).rules( "add", {
              required: true,
              maxlength: 50,
              nameregex: true,
              messages: {
                required: "Degree can't be blank",
                maxlength: 'Maximum 50 characters are allowed.',
                nameregex: 'Only allow letters'
              }
            });
          }else if ($(input).attr('class').includes('percentage_gpa')) {
            $(input).rules( "add", {
              required: true,
              number: true,
              messages: {
                required: "Institute Name can't be blank",
                number: "Only allow digits"
              }
            });
          }else if ($(input).attr('class').includes('specialization')) {
            $(input).rules( "add", {
              required: true,
              maxlength: 100,
              nameregex: true,
              messages: {
                required: "Specialization can't be blank",
                maxlength: 'Maximum 100 characters are allowed.',
                nameregex: 'Only allow letters'
              }
            });
          }else if ($(input).attr('class').includes('start_date')) {
            $(input).rules( "add", {
              required: true,
              messages: {
                required: "Start Date can't be blank"
              }
            });
          }
        }
      })
    })

    $('#emergency-form').validate()
    $('#emergency_details').find(".nested-fields").each(function(index, element) {
      console.log('emergency')
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          if ($(input).attr('class').includes('person_name')) {
            $(input).rules( "add", {
              required: true,
              maxlength: 25,
              nameregex: true,
              messages: {
                required: "Person Name can't be blank",
                maxlength: 'Maximum 25 characters are allowed.',
                nameregex: 'Only allow letters'
              }
            });
          } else if ($(input).attr('class').includes('relation')) {
            $(input).rules( "add", {
              required: true,
              maxlength: 50,
              nameregex: true,
              messages: {
                required: "Relation can't be blank",
                maxlength: 'Maximum 50 characters are allowed.',
                nameregex: 'Only allow letters'
              }
            });
          } else if ($(input).attr('class').includes('mobile_number')) {
            $(input).rules( "add", {
              required: true,
              maxlength: 10,
              minlength: 10,
              pincoderegex: true,
              messages: {
                required: "Mobile Number can't be blank",
                maxlength: 'Emergency contact number length must be of 10 digits',
                minlength: 'Emergency contact number length must be of 10 digits',
                pincoderegex: 'Contains digits only'
              }
            });
          }
        }
      })
    })

    $('#medical-form').validate()
    $('#medical_details').find(".nested-fields").each(function(index, element) {
      console.log('medical details')
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
            $(input).rules( "add", {
              required: true,
              maxlength: 100,
              alphanumeric: true,
              messages: {
                required: "Medical issue can't be blank",
                maxlength: 'Maximum 100 characters are allowed.',
                alphanumeric: 'Only alphanumerics are allowed'
              }
            });
        }
      })
    })
    $('#social-form').validate()
    $('#social-links').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('class').includes('plateform_name')) {
          $(input).rules( "add", {
            required: true,
            maxlength: 25,
            nameregex: true,
            messages: {
              required: "Platform Name can't be blank",
              maxlength: 'Maximum 150 characters are allowed.',
              nameregex: 'Only allow letters'
            }
          });
        } else if ($(input).attr('class').includes('link')) {
          $(input).rules( "add", {
            required: true,
            messages: {
              required: "Link can't be blank",
            }
          });
        }
      })
    })

    $('#documents-form').validate()
    $('#documents').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('class').includes('document_name')) {
          $(input).rules( "add", {
            required: true,
            maxlength: 25,
            nameregex: true,
            messages: {
              required: "Document Name can't be blank",
              maxlength: 'Maximum 150 characters are allowed.',
              nameregex: 'Only allow letters'
            }
          });
        } else if ($(input).attr('class').includes('document_file')) {
          $(input).rules( "add", {
            required: true,
            messages: {
              required: "Please up",
            }
          });
        }
      })
    })

    $('#bank-form').validate()
    $('#bank_details').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          if($(input).attr('class').includes('bank_name')){
            $(input).rules( "add", {
              required: true,
              maxlength: 30,
              nameregex: true,
              messages: {
                required: "Bank Name can't be blank",
                maxlength: 'Maximum 30 characters are allowed.',
                nameregex: 'Only allow letters'
              }
            });
          }else if ($(input).attr('class').includes('account_number')) {
            $(input).rules( "add", {
              required: true,
              digitregex: true,
              minlength: 7,
              maxlength: 25,
              messages: {
                required: "Account Number can't be blank",
                digitregex: 'Account Number must be digit.',
                minlength: 'Account Number must be between 7 - 25 length',
                maxlength: 'Account Number must be between 7 - 25 length'
              }
            });
          }else if ($(input).attr('class').includes('ifsc_code')) {
            $(input).rules( "add", {
              required: true,
              maxlength: 11,
              messages: {
                required: "IFSC Code can't be blank",
                maxlength: 'Maximum 11 characters are allowed.'
              }
            });
          }else if ($(input).attr('class').includes('bank_address')) {
            $(input).rules( "add", {
              required: true,
              maxlength: 100,
              messages: {
                required: "Bank Address can't be blank",
                maxlength: 'Maximum 100 characters are allowed.'
              }
            });
          }
        }
      })
    })

    $.validator.addMethod('phoneregex', function(value, element){
      return this.optional(element) || /\d{10}\b/g.test(value);
    },'Contains digits only');

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');

    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^\b[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(value);
    },'Contains alphabets and number only.');

    $.validator.addMethod("alphanumeric", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9 ]+$/.test(value);
    });

    $.validator.addMethod('passwordregex', function(value, element){
      return this.optional(element) || /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).+$/.test(value);
    },'Password must contains one uppercase, lowercase, number and special character');

    $.validator.addMethod('pincoderegex', function(value, element){
      return this.optional(element) || /\d\b/g.test(value);
    },'Contains digits only');

  }
}
